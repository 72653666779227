import { Fields } from "@app/services/selective-processes/fields/fields";

// Idiomas suportados pelas páginas
export const poLocales = ['pt', 'en', 'es', 'ru'];
// Idioma padrão
export const poLocaleDefault = 'pt';

/**
 * @deprecated
 * Utilize o método `getShortBrowserLanguage`.
 *
 * @description
 * Retorna idioma do browser ou o idioma padrão.
 */
export function browserLanguage() {
  return getShortBrowserLanguage();
}

/**
 * Converte e formata os bytes em formato mais legível para o usuário.
 *
 * Por exemplo:
 * - 31457280 em 30 MB.
 * - 21474836480 em 20 GB.
 * - 12.5666666 em 12.57 Bytes (duas casas decimais).
 *
 * @param bytes {number} Valor em bytes
 * @param decimals {number} Quantidade de casas decimais que terá após a conversão.
 */
export function formatBytes(bytes: number, decimals = 2): string {

  if (!bytes) {
    return undefined;
  }

  const multiplier = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const result = Math.floor(Math.log(bytes) / Math.log(multiplier));
  decimals = decimals < 0 ? 0 : decimals;

  return `${parseFloat((bytes / Math.pow(multiplier, result)).toFixed(decimals))} ${sizes[result]}`;
}

export function getAllPossibleArrayCombinations(array_of_arrays) {
  return array_of_arrays.reduce((a, b) => a.flatMap(x => b.map(y => [...x, y])), [[]])
}

/**
 * Retira propriedades de um objeto, percorrendo todo o objeto.
 * 
 * @template T Objeto generico.
 * @param data Dados no qual as propriedades especificadas serão removidas.
 * @param prop Nome das propriedades que serão removidas, recebe uma string ou um array de string.
 * @returns Mesmo objeto, apenas sem as propriedades passadas.
 */
export function removePropertys<T extends object | any[]>(data: T, prop: string | string[]): T {
  if (data === null || data === undefined) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(item => removePropertys(item, prop)) as T;
  }

  if (typeof data === 'object' && data !== null) {
    const propsToRemove = Array.isArray(prop) ? prop : [prop];
    return Object.keys(data).reduce((acc, key) => {
      if (!propsToRemove.find(p => p == key)) {
        acc[key] = removePropertys(data[key], prop);
      }
      return acc;
    }, {} as T);
  }
  return data;
}

/**
 * Retorna o idioma atual do navegador
 */
export function getBrowserLanguage(): string {
  // navigator.userLanguage is the value for IE10
  const language = navigator.language || navigator['userLanguage'];
  const shortLanguage = getShortLanguage(language);

  return poLocales.includes(shortLanguage) ? language : poLocaleDefault;
}

/**
 * Retorna o idioma do navegador, com somente as duas primeiras letras. Por exemplo: "pt" ou "es".
 *
 * Caso o valor retornado pelo navegador não estiver dentro dos idiomas suportados pelo PO,
 * será retornado a linguagem padrão (poLocaleDefault).
 */
export function getShortBrowserLanguage(): string {
  return getShortLanguage(getBrowserLanguage());
}

/**
 * Retorna o idioma com somente a abreviação do idioma (duas primeiras letras).
 * Por exemplo: "pt" ou "es".
 *
 * @param language {string} linguagem.
 *
 * @returns sigla do idioma padrão {string}.
 *
 * @default pt
 */
export function getShortLanguage(language: string): string {
  return (language || poLocaleDefault).toLowerCase().substring(0, 2);
}

export function isLanguage(value) {
  const languageRegex = new RegExp('^[a-z]{2}(\-[a-z]{2})?$', 'i');

  return languageRegex.test(value);
}

/* istanbul ignore next */
export function reloadCurrentPage() {
  window.location.assign(location.href);
}

export function convertToBoolean(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();
    return (val === 'true' || val === 'on' || val === '');
  }

  if (typeof val === 'number') {
    return val === 1;
  }

  return !!val;
}

export function convertToInt(value: any, valueDefault?: any): number {
  const validNumber = parseInt(value, 10);
  const validDefaultValue = parseInt(valueDefault, 10);
  const defaultValue = validDefaultValue || validDefaultValue === 0 ? validDefaultValue : undefined;

  return validNumber || validNumber === 0 ? validNumber : defaultValue;
}

export function isTypeof(object: any, type: any) {
  return typeof object === type;
}

export function deep(o, u) {
  // tslint:disable-next-line: one-variable-per-declaration
  const keysO = Object.keys(o), keysU = Object.keys(u);
  keysU.forEach((k) => {
    if (u[k] !== null && typeof u[k] === 'object') {
      if (o[k] === null || typeof o[k] !== 'object') {
        if (k === 'logics') {
          o[k] = [];
        } else if (k === 'query') {
          o[k] = [];
        } else if (k === 'forwardData') {
          o[k] = [];
        } else if (k === 'integrations') {
          o[k] = [];
        } else if (k === 'component_upload_file_types') {
          o[k] = [];
        } else if (k === 'totvsActions') {
          o[k] = [];
        } else {
          o[k] = {};
        }
      }
      deep(o[k], u[k]);
      return;
    }

    if (typeof u[k] === 'undefined') {
      u[k] = o[k];
    }
    o[k] = u[k];
  });
}

/**
 * Method to deep update the values of the source object from new object without changing source  object reference
 *
 * @export
 * @param sourceObj
 * @param newObj
 */
export function updateObjKeepingRef(sourceObj: any, newObj: any): void {
  Object.keys(newObj).forEach(key => {
    // if value is object and instance is not Date
    if (newObj[key] && typeof newObj[key] === 'object' && sourceObj[key] && !(newObj[key] instanceof Date)) {
      updateObjKeepingRef(sourceObj[key], newObj[key]);
    } else {
      // updating properties
      if (typeof newObj[key] === 'undefined') {
        newObj[key] = sourceObj[key];
      } else {
        sourceObj[key] = newObj[key];

      }
    }
  });
}

export function updateObjKeepingRefWithoutMapArray(sourceObj: any, newObj: any): void {
  Object.keys(newObj).forEach(key => {
    // if value is object and instance is not Date
    if (newObj[key] && typeof newObj[key] === 'object' && sourceObj[key] && !(newObj[key] instanceof Date) && !Array.isArray(newObj[key])) {
      updateObjKeepingRefWithoutMapArray(sourceObj[key], newObj[key]);
    } else {
      // updating properties
      if (typeof newObj[key] === 'undefined') {
        newObj[key] = sourceObj[key];
      } else {
        sourceObj[key] = newObj[key];

      }
    }
  });
}

export function isObject(obj) {
  return obj === Object(obj);
}

/**
 *
 * @param fn Função que será executada dentro do contexto. Podendo ser o nome da função
 * ou a referência da mesma.
 *
 * @param context Contexto do qual a função será executada.
 */
export function callFunction(fn: any, context: any, param?): void {
  if (isTypeof(fn, 'function')) {
    fn.call(context, param);
  } else {
    context[fn](param);
  }
}

export function convertIsoToDate(value: string, start: boolean, end: boolean) {
  if (value) {
    const day = parseInt(value.substring(8, 10), 10);
    const month = parseInt(value.substring(5, 7), 10);
    const year = parseInt(value.substring(0, 4), 10);
    if (start) {
      const date = new Date(year, month - 1, day, 0, 0, 0);

      setYearFrom0To100(date, year);

      return date;
    } else if (end) {
      const date = new Date(year, month - 1, day, 23, 59, 59);

      setYearFrom0To100(date, year);

      return date;
    } else {
      const milliseconds = Date.parse(value);
      const timezone = new Date().getTimezoneOffset() * 60000;
      return new Date(milliseconds + timezone);
    }
  }
}

/**
 *
 * Converts date to Brazilian format and returning and date.
 *
 * Mandatory options:
 * @param date format `0000-00-00`
 *
 * Options:
 * @param time format `0000-00-00 00:00:00`
 * @param badgeNew notifies you that it is a new
 * @param person who created or edited
 *
 * @returns date
 */
export function convertDateBR(date: string, badgeNew?: boolean) {
  if (date) {
    const day = date.substring(8, 10);
    const month = date.substring(5, 7);
    const year = parseInt(date.substring(0, 4), 10);
    const hour = date.substring(11, 19);

    return `${day}/${month}/${year} às ${hour}`;
  } else if (typeof date === 'undefined' && badgeNew) {
    return '<span class="ps-badge">Novo</span>';
  }
}

export function convertDateToISODate(date: Date) {
  if (date) {
    const getMonth = date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = getMonth < 10 ? '0' + getMonth : getMonth;
    const year = formatYear(date.getFullYear());

    return year + '-' + month + '-' + day;
  } else {
    return null;
  }
}

export function convertDateToISOExtended(date: Date, time?: string) {
  if (date) {

    const getMonth = date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = getMonth < 10 ? '0' + getMonth : getMonth;
    const year = formatYear(date.getFullYear());

    const dateString = date.toString();

    if (time !== null) {
      return year + '-' + month + '-' + day + time;
    } else {
      return year + '-' + month + '-' + day + 'T' + dateString.substring(16, 24) +
        dateString.substring(28, 31) + ':' + dateString.substring(31, 33);
    }
  } else {
    return null;
  }
}

/**
 *
 * @param type `number`
 */
export function convertSituation(type: number) {
  if (type === 0 || typeof type === 'undefined') {
    return `<span class="ps-status-color-lightGrey">Desativado</span>`;
  } else {
    return `<span class="ps-status-color-green">Ativado</span>`;
  }
}

/**
 * Transforma o ano em uma string no formato yyyy e caso o ano seja menor que 1000 preenche com zeros a esquerda.
 * @param year Ano
 */
export function formatYear(year: number) {

  if (year >= 1000) {
    return year.toString();
  }

  if (year > 99 && year < 1000) {
    return `0${year}`;
  }

  if (year > 9 && year < 100) {
    return `00${year}`;
  }

  if (year >= 0 && year < 10) {
    return `000${year}`;
  }

}
// Verifica se o navegador em que está sendo usado é Internet Explorer ou Edge
export function isIEOrEdge() {
  const userAgent = window.navigator.userAgent;

  return /msie\s|trident\/|edge\//i.test(userAgent);
}

// Verifica se o navegador em que está sendo usado é Internet Explorer
export function isIE() {
  const userAgent = window.navigator.userAgent;

  return /msie\s|trident/i.test(userAgent);
}

// Verifica se o navegador em que está sendo usado é Firefox
export function isFirefox() {
  const userAgent = window.navigator.userAgent;

  return userAgent.toLowerCase().indexOf('firefox') > -1;
}

// Verifica qual o dispositivo que está sendo usado
export function isMobile() {
  const userAgent = window.navigator.userAgent;

  return userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i);
}

export function isEquals(value, comparedValue) {
  return JSON.stringify(value) === JSON.stringify(comparedValue);
}

export function isKeyCodeEnter(event: any): boolean {
  return event.keyCode === 13 || event.which === 13;
}

/**
 * Caso o ano original da data seja entre 0 e 100 atribui esse valor ao ano, pois o `new Date` do javascript transforma o ano para 190X.
 * @param date Data
 * @param year Ano original
 */
export function setYearFrom0To100(date: Date, year: number) {
  if (year >= 0 && year < 100) {
    date.setFullYear(year);
  }
}

export function sortOptionsByProperty(options: Array<any>, property: string) {
  options.sort((optionA, optionB) => {
    optionA = optionA[property].toString().toLowerCase();
    optionB = optionB[property].toString().toLowerCase();

    if (optionA < optionB) {
      return -1;
    }
    if (optionA > optionB) {
      return 1;
    }
    return 0;
  });
}

export function removeDuplicatedOptions(list: Array<any>) {
  for (let i = 0; i < list.length; i++) {
    if (i === 0) { continue; }

    if (list.findIndex(op => op.value === list[i].value) !== i) {
      list.splice(i, 1);
      i--;
    }
  }
}

export function removeUndefinedAndNullOptions(list: Array<any>) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].value === undefined || list[i].value === null) {
      list.splice(i, 1);
      i--;
    }
  }
}

export function removeAnItem(list: Array<any>, argument: string, value: any) {
  for (const key in list) {
    if (list.hasOwnProperty(key)) {
      if (list[key][argument] === value) {
        list.splice(convertToInt(key), 1);
      }
    }
  }
  return list;
}

export function groupBy(array, keyParam: string) {
  Object.defineProperty(Array.prototype, 'group', {
    enumerable: false,
    value(key) {
      const map = {};
      this.map(e => ({ k: key(e), d: e })).forEach(e => {
        if (typeof e.k !== 'undefined') {
          map[e.k] = map[e.k] || [];
          map[e.k].push(e.d);
        }
      });
      return Object.keys(map).map(k => ({ key: k, data: map[k] }));
    }
  });
  return array.group(item => item[keyParam]);
}


export function buildReturnOfTotvsData(query: Array<any>, content) {
  if (query.length > 0) {

    for (const key in query) {
      if (Object.prototype.hasOwnProperty.call(query, key)) {
        const element = query[key];
        for (const keyField in element.fields_query) {
          if (Object.prototype.hasOwnProperty.call(element.fields_query, keyField)) {
            const field = element.fields_query[keyField];
            if (field.relation_id !== null) {
              if (element.query_data !== false && typeof element.query_data !== 'undefined' && (Array.isArray(element.query_data) || element.lazy_load == 1)) {
                setDataQuery(element.query_data, field.field_id, field.relation_id, true, content, element.lazy_load);
              } else {
                setDataQuery([], field.field_id, field.relation_id, true, content, element.lazy_load);
              }
            } else {
              if (element.query_data !== false && typeof element.query_data !== 'undefined') {
                setDataQuery(element.query_data, field.field_id, null, false, content, element.lazy_load);
              } else {
                setDataQuery([], field.field_id, null, false, content, element.lazy_load);
              }
            }
          }
        }
      }
    }

  }
}

export function returnsItemsInsideContent(content) {
  let contentFields = [];
  content.reduce((previousValue, currentValue) => {
    if (currentValue.type === 'container') {
      const valueContent = getFieldsWithinContent(currentValue.container_content);
      contentFields.push(...valueContent);
    } else {
      contentFields = [...contentFields, currentValue];
    }
  }, contentFields);
  return contentFields;
}

export function getFieldsWithinContent(content) {
  const contentFields = [];
  content.filter(field => {
    if (field.type === 'container') {
      field.container_content.filter(containerFields => {
        if (containerFields.type !== 'container') {
          contentFields.push(containerFields);
        }
        if (containerFields.type === 'container') {
          contentFields.push(...getFieldsWithinContent(containerFields.container_content));
        }
      });
    } else {
      contentFields.push(field);
    }

  });
  return contentFields;
}

export function setDataQuery(data, fieldId, relationId, relation, content, lazy_load = 0) {
  if (relation) {
    content.forEach(item => {
      if (item.field_relation) {
        if (item.relation_id === relationId) {
          item.related_data = data;
          item.lazy_load = lazy_load;
        }
      }
      if (item.type === 'container') {
        setDataQuery(data, fieldId, relationId, relation, item.container_content, lazy_load);
      }
    });
  } else {
    for (const key in content) {
      if (Object.prototype.hasOwnProperty.call(content, key)) {
        const element = content[key];
        if (element.component_type_id == 6) {
          setDataQueryContainer(data, element.container_content, fieldId, lazy_load);
        }
        if (element.field_id === fieldId && element.defined_source != 3) {
          element.data = data;
          element.lazy_load = lazy_load;
        }
      }
    }
  }
}

function setDataQueryContainer(data, content, fieldId, lazy_load = 0) {
  for (const key in content) {
    if (Object.prototype.hasOwnProperty.call(content, key)) {
      const element = content[key];
      if (element.component_type_id == 6) {
        setDataQueryContainer(data, element.container_content, fieldId, lazy_load);
      }
      if (element.field_id === fieldId && element.defined_source != 3) {
        element.data = data;
        element.lazy_load = lazy_load;
      }
    }
  }
}

export function setDataQueryInFieldsWithRelation(data, fieldId, fieldRelation: Array<any>) {
  fieldRelation.map(field => {
    if (field.field_id === fieldId) {
      field.data = data;
    }
  });
}

export function isEmpty(str) {
  return (!str || 0 === str.length);
}

export function isBlank(str) {
  return (!str || /^\s*$/.test(str));
}

export function removeAnItens(listOne: Array<any>, listTwo: Array<any>, argument: string) {
  const filter = listOne.filter((elOne) => {
    return !listTwo.find((elTwo) => {
      return elTwo[argument] === elOne[argument];
    });
  });
  return filter;
}

export function removeAnItensTwoArgument(listOne: Array<any>, listTwo: Array<any>, argument: string, argumentTwo: string) {
  const filter = listOne.filter((elOne) => {
    return !listTwo.find((elTwo) => {
      return elTwo[argumentTwo] === elOne[argument];
    });
  });
  return filter;
}

export function validValue(value: any) {
  return (value !== null && value !== undefined && value !== '') || value === false;
}

export function validMultipleValue(params: any) {
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const element = params[key];
      if (!validValue(element)) {
        return false;
      }
    }
  }
  return true;
}


export function validSettingsSystemQuery(data: Array<any>) {
  for (const iterator of data) {
    if (iterator.status === 0) {
      return false;
    }
  }
  return true;
}

export function isExternalLink(url): boolean {
  return url ? url.startsWith('http') : false;
}

export function openExternalLink(url): void {
  window.open(url, '_blank');
}

export function getFormattedLink(link: string): string {
  let formattedLink = '';
  // Retira todos os pontos no começo da URL.
  if (link) {
    formattedLink = link.replace(/^(\.)+/g, '');
  }
  // Verifica se foi utilizado uma rota que não comece com barra.
  if (!formattedLink.startsWith('/')) {
    formattedLink = '/'.concat(formattedLink);
  }
  return formattedLink;
}

/**
 * Método responsável por ordenar dois valores.
 *
 * @param leftSide Primeiro valor a ser comparado.
 * @param rightSide Segundo valor a ser comparado.
 * @param ascending Determina se será em ordem ascendente ou descendente.
 */
export function sortValues(leftSide: string, rightSide: string, ascending: boolean = true): number {
  const left = isTypeof(leftSide, 'string') ? leftSide.toLowerCase() : leftSide;
  const right = isTypeof(rightSide, 'string') ? rightSide.toLowerCase() : rightSide;

  if (ascending) {
    if (left < right) {
      return -1;
    } else if (left > right) {
      return 1;
    }
  } else if (ascending === false) {
    if (left < right) {
      return 1;
    } else if (left > right) {
      return -1;
    }
  }
  return 0;
}

export function validateDateRange(date: Date, dateStart: Date, dateEnd: Date) {
  if (dateStart && dateEnd) {
    return (date >= dateStart && date <= dateEnd);
  } else if (dateStart && !dateEnd) {
    return (date >= dateStart);
  } else if (!dateStart && dateEnd) {
    return (date <= dateEnd);
  } else {
    return true;
  }
}

export function uuid() {
  function hex4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return hex4() + hex4() + '-' + hex4() + '-' + hex4() + '-' +
    hex4() + '-' + hex4() + hex4() + hex4();
}

export function capitalizeFirstLetter(text: string): string {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

/**
 * Mapeia um novo array apenas com as propriedades definidas pelo desenvolvedor baseado em um array de
 * origem.
 *
 * Exemplo:
 *
 * ```
 * const people = [
 *  { id: 1, name: 'Fulano', birthdate: '1980-11-01', genre: 'Male', city: 'São Paulo', dependents: 2 },
 *  { id: 2, name: 'Beltrano', birthdate: '1997-01-21', genre: 'Female', city: 'Joinville', dependents: 0 },
 *  { id: 3, name: 'Siclano', birthdate: '1995-07-15', genre: 'Male', city: 'Joinville', dependents: 0 }
 * ];
 *
 * const properties = ['id', 'name'];
 *
 * const idAndName = mapArrayByProperties(people, properties);
 *
 * console.log(idAndName); // [{ id: 1, name: 'Fulano' }, { id: 2, name: 'Beltrano' }, { id: 3, name: 'Siclano' }]
 * ```
 *
 * Um outro uso para o método é "parear" todos os objetos do array com as mesmas propriedades.
 *
 * ```
 * const customers = [
 *  { id: 1, name: 'Fulano', city: 'São Paulo', dependents: 2 }, // sem genre
 *  { id: 2, name: 'Beltrano', genre: 'Female', city: 'Joinville' }, // sem dependents
 *  { id: 3, name: 'Siclano', genre: 'Male', city: 'Joinville', dependents: 0 }
 * ];
 * const properties = ['id', 'name', 'city', 'genre', 'dependents'];
 *
 * const pattern = mapArrayByProperties(customers, properties);
 * console.log(pattern);
 *
 * // [
 * //   { id: 1, name: 'Fulano', city: 'São Paulo', genre: undefined, dependents: 2 },
 * //   { id: 2, name: 'Beltrano', city: 'Joinville', genre: 'Female', dependents: undefined },
 * //   { id: 3, name: 'Siclano', city: 'Joinville', genre: 'Male', dependents: 0 }
 * // ]
 * ```
 *
 * @param items {Array<any>} Array de items original.
 * @param properties {Array<string>} Array de string com a lista de propriedades que devem ser retornadas.
 *
 * @returns Array<any>
 */
export function mapArrayByProperties(items: Array<any> = [], properties: Array<string> = []): Array<any> {
  return items.map(item => mapObjectByProperties(item, properties));
}

/**
 * Mapeia um novo objeto apenas com as propriedades definidas pelo desenvolvedor.
 *
 * Exemplo:
 *
 * ```
 * const person = { id: 1, name: 'Fulano', birthdate: '1980-11-01', genre: 'Male', city: 'São Paulo', dependents: 2 };
 *
 * const properties = ['id', 'name'];
 *
 * const idAndName = mapObjectByProperties(person, properties);
 *
 * console.log(idAndName); // { id: 1, name: 'Fulano' }
 * ```
 *
 * @param object {Array<any>} Array de items original.
 * @param properties {Array<string>} Array de string com a lista de propriedades que devem ser retornadas.
 *
 * @returns Array<any>
 */
export function mapObjectByProperties(object: any = {}, properties: Array<string> = []) {
  const getSelectedProperties = (selectedProperties, property) => ({ ...selectedProperties, [property]: object[property] });

  return properties.reduce(getSelectedProperties, {});
}

export function mapArrayByPropertiesLeavingOnlyValue(items: Array<any> = [], properties: Array<string> = []): Array<any> {
  return items.map(item => mapObjectByPropertiesLeavingOnlyValue(item, properties));
}

export function mapObjectByPropertiesLeavingOnlyValue(object: any = {}, properties: Array<string> = []) {
  const getSelectedProperties = (selectedProperties, property) => object[property];

  return properties.reduce(getSelectedProperties, []);
}


/**
 * Retorna os valores de um objeto dentro de um array.
 *
 * > Simula o Object.values(obj), o mesmo deve ser removido assim que a versão typescrit for atualizada.
 *
 * @param object Objeto de onde será pego os valores.
 */
export function valuesFromObject(object: any = {}): Array<any> {
  return Object.keys(object).map(property => object[property]);
}

export function removeArrayDuplicates(array: Array<any>) {
  array.reduce((unique, item) => {
    return unique.includes(item) ? unique : [...unique, item];
  }, []);
}

export function mapArrayByPropertieDataSource(propertie, array: Array<any>) {
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      const value = element[propertie];
      if (element[propertie]) {
        element[propertie] = value[propertie] ? value[propertie] : null;
      }
      element.parameter_type_id = convertToInt(element.parameter_type_id, null);
    }
  }
  return array;
}

export function mapArrayQueryRemoveDataSource(array: Array<any>) {
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (typeof element.code !== 'undefined') {
        array.splice(convertToInt(key));
      }
    }
  }
  return array;
}

/**
 * Converte um arquivo em base64.
 *
 * @param file arquivo que será convertido.
 */
export function convertImageToBase64(file: File): Promise<any> {
  return new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

/**
 * Converte um número em decimal baseado na quantidade de casas decimais.
 *
 * Caso o valor seja inválido, será retornado o valor `undefined`.
 * Valores inválidos são: `false`, `NaN`, `strings` que não numéricas, `undefined` e `null`.
 *
 * @param number valor que será convertido
 * @param decimalsPlace quantidade de casas decimais
 */
export function convertNumberToDecimal(number: any, decimalsPlace: number): number {
  const isValidValue = (number || number === 0) && !isNaN(number);

  const floatValue = isValidValue ? parseFloat(number) : undefined;

  try {
    return parseFloat(floatValue.toFixed(decimalsPlace));
  } catch {
    return floatValue;
  }
}

/**
 * Retorna uma copia do objeto sujo, sem as propriedades nulas ou indefinidas.
 * Retorna o objeto sem as propriedades que contém valores nulos ou indefinidos.
 *
 * @param dirtyObject
 */
export function clearObject(dirtyObject: object): any {
  const cleanObject = {};

  Object.keys(dirtyObject).forEach(key => {
    if (dirtyObject[key] !== null && dirtyObject[key] !== undefined) {
      cleanObject[key] = dirtyObject[key];
    }
  });

  return cleanObject;
}

export function objectLoad(dirtyObject): any {
  let cleanObject = false;

  Object.keys(dirtyObject).forEach(key => {
    if (dirtyObject[key] !== undefined) {
      cleanObject = true;
    }
  });

  return cleanObject;
}

export function validateObjectType(value: any) {
  return isTypeof(value, 'object') && !Array.isArray(value) ? value : undefined;
}

/**
 * Retorna o ultimo item do array
 * @param array any
 */
export function lastItemInArray(array: Array<any>) {
  return array[array.length - 1];
}

export function getTheFieldsSavedInForm(dataForm) {
  let dataFields = [];
  dataForm.content.map(value => {
    const use_totvs_oracle = dataForm.use_totvs_oracle || 0;
    if (value.type === 'container') {
      dataFields = [...dataFields, ...getFieldsWithinAContainer(value.container_content, use_totvs_oracle)]
    }
    const field = getFieldsOutOfAContainer(value, use_totvs_oracle);
    if (field) {
      dataFields = [...dataFields, field]
    }
  });
  return dataFields;
}

export function getFieldsWithinAContainer(containerFields: Array<any>, use_totvs_oracle) {
  const flat = [];
  containerFields.forEach(item => {
    if (item.id && item.field_id && item.id !== null && item.field_id !== null) {
      item.field_settings_id = item.id;
      setDisplayTableAttributeToField(item, use_totvs_oracle);
      flat.push(item)
    }
    if (item.type === 'container') {
      flat.push(...getFieldsWithinAContainer(item.container_content, use_totvs_oracle));
    }
  });
  return flat;
}

export function getFieldsOutOfAContainer(field, use_totvs_oracle) {
  if (field.id && field.field_id && field.id !== null && field.field_id !== null) {
    field.field_settings_id = field.id;
    setDisplayTableAttributeToField(field, use_totvs_oracle);
    return field;
  }
  return false;
}

export function setDisplayTableAttributeToField(field, use_totvs_oracle) {
  field.display_table = [];
  field.display_table = [...field.display_table, addDisplayTableTypeCOD(field, use_totvs_oracle)];
  field.display_table = [...field.display_table, addDisplayTableTypeDISPLAY(field, use_totvs_oracle)];
}

export function addDisplayTableTypeCOD(field, use_totvs_oracle) {
  return {
    field_id: field.field_id,
    key: field.key,
    rubeus_table: field.rubeus_table,
    ps_table: field.ps_table,
    title: use_totvs_oracle ? `C_${field.totvs_alternative_name}` : `COD.${field.totvs_table}.${field.totvs_field}`,
    description: 'O valor presente será o identificador.'
  };
}

export function addDisplayTableTypeDISPLAY(field, use_totvs_oracle) {
  return {
    field_id: field.field_id,
    key: field.key,
    rubeus_table: field.rubeus_table,
    ps_table: field.ps_table,
    title: use_totvs_oracle ? `D_${field.totvs_alternative_name}` : `DISPLAY.${field.totvs_table}.${field.totvs_field}`,
    description: 'Os valores presentes serão exibidos para que o usuário escolha uma ou mais opções.'
  };
}

/**
 * Function to get Site Collection URL
 * Samples:
 *      "https://domain.sharepoint.com/sites/intranet"
 */
export function getSiteCollectionUrl(): string {
  if (window
    && "location" in window
    && "protocol" in window.location
    && "pathname" in window.location
    && "host" in window.location) {
    let baseUrl = window.location.protocol + "//" + window.location.host;
    const pathname = window.location.pathname;
    const siteCollectionDetector = "/sites/";
    if (pathname.indexOf(siteCollectionDetector) >= 0) {
      baseUrl += pathname.substring(0, pathname.indexOf("/", siteCollectionDetector.length));
    }
    return baseUrl;
  }
  return null;
}

/**
 * Function to get Current Site Url
 * Samples:
 *      "https://domain.sharepoint.com/sites/intranet/subsite/Pages/Home.aspx"
 */
export function getCurrentAbsoluteSiteUrl(): string {
  if (window
    && "location" in window
    && "protocol" in window.location
    && "pathname" in window.location
    && "host" in window.location) {
    return window.location.protocol + "//" + window.location.host + window.location.pathname;
  }
  return null;
}

/**
 * Function to get Current Site Url
 * Samples:
 *      "/sites/intranet"
 */
export function getWebServerRelativeUrl(): string {
  if (window
    && "location" in window
    && "pathname" in window.location) {
    return window.location.pathname.replace(/\/$/, "");
  }
  return null;
}

/**
 * Function to get Layout Page Url
 * Replacement in SPFx for SP.Utilities.Utility.getLayoutsPageUrl('sp.js')
 * Samples:
 *      getLayoutsPageUrl('sp.js')
 *      "/sites/intranet/_layouts/15/sp.js"
 */
export function getLayoutsPageUrl(libraryName: string): string {
  if (window
    && "location" in window
    && "pathname" in window.location
    && libraryName !== "") {
    return window.location.pathname.replace(/\/$/, "") + "/_layouts/15/" + libraryName;
  }
  return null;
}

/**
 * Function to get Current Domain Url
 * Samples:
 *      "https://domain.sharepoint.com"
 */
export function getAbsoluteDomainUrl(): string {
  if (window
    && "location" in window
    && "protocol" in window.location
    && "host" in window.location) {
    return window.location.protocol + "//" + window.location.host;
  }
  return null;
}


export function deepEquals(a, b) {
  if (a instanceof Array && b instanceof Array) {
    return arraysEqual(a, b);
  }
  if (Object.getPrototypeOf(a) === Object.prototype && Object.getPrototypeOf(b) === Object.prototype) {
    return objectsEqual(a, b);
  }
  if (a instanceof Map && b instanceof Map) {
    return mapsEqual(a, b);
  }
  if (a instanceof Set && b instanceof Set) {
    throw new Error('Error: set equality by hashing not implemented.');
  }
  if ((a instanceof ArrayBuffer || ArrayBuffer.isView(a)) && (b instanceof ArrayBuffer || ArrayBuffer.isView(b))) {
    return typedArraysEqual(a, b);
  }
  return a == b;  // see note[1] -- IMPORTANT
}

export function arraysEqual(a, b) {
  if (a.length != b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (!deepEquals(a[i], b[i])) {
      return false;
    }
  }
  return true;
}
export function objectsEqual(a, b) {
  const aKeys = Object.getOwnPropertyNames(a);
  const bKeys = Object.getOwnPropertyNames(b);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  aKeys.sort();
  bKeys.sort();
  for (let i = 0; i < aKeys.length; i++) {
    if (aKeys[i] !== bKeys[i]) { // keys must be strings
      return false;
    }
  }
  return deepEquals(aKeys.map(k => a[k]), aKeys.map(k => b[k]));
}
export function mapsEqual(a, b) {
  if (a.size !== b.size) {
    return false;
  }
  const aPairs: any = Array.from(a);
  const bPairs: any = Array.from(b);
  aPairs.sort((x, y) => x[0] < y[0]);
  bPairs.sort((x, y) => x[0] < y[0]);
  for (let i = 0; i < a.length; i++) {
    if (!deepEquals(aPairs[i][0], bPairs[i][0]) || !deepEquals(aPairs[i][1], bPairs[i][1])) {
      return false;
    }
  }
  return true;
}

export function typedArraysEqual(a, b) {
  a = new Uint8Array(a);
  b = new Uint8Array(b);
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}


export function DiffObjects(o1, o2) {
  // choose a map() impl.
  var map = Array.prototype.map ?
    function(a) { return Array.prototype.map.apply(a, Array.prototype.slice.call(arguments, 1)); } :
    function(a, f) {
      var ret = new Array(a.length), value;
      for (var i = 0, length = a.length; i < length; i++)
        ret[i] = f(a[i], i);
      return ret.concat();
    };

  // shorthand for push impl.
  var push = Array.prototype.push;

  // check for null/undefined values
  if ((o1 == null) || (o2 == null)) {
    if (o1 != o2)
      return [["", "null", o1 != null, o2 != null]];

    return undefined; // both null
  }
  // compare types
  if ((o1.constructor != o2.constructor) ||
    (typeof o1 != typeof o2)) {
    return [["", "type", Object.prototype.toString.call(o1), Object.prototype.toString.call(o2)]]; // different type

  }

  // compare arrays
  if (Object.prototype.toString.call(o1) == "[object Array]") {
    if (o1.length != o2.length) {
      return [["", "length", o1.length, o2.length]]; // different length
    }
    var diff = [];
    for (var i = 0; i < o1.length; i++) {
      // per element nested diff
      var innerDiff = DiffObjects(o1[i], o2[i]);
      if (innerDiff) { // o1[i] != o2[i]
        // merge diff array into parent's while including parent object name ([i])
        push.apply(diff, map(innerDiff, function(o, j) { o[0] = "[" + i + "]" + o[0]; return o; }));
      }
    }
    // if any differences were found, return them
    if (diff.length)
      return diff;
    // return nothing if arrays equal
    return undefined;
  }

  // compare object trees
  if (Object.prototype.toString.call(o1) == "[object Object]") {
    var diff = [];
    // check all props in o1
    for (var prop in o1) {
      // the double check in o1 is because in V8 objects remember keys set to undefined
      if ((typeof o2[prop] == "undefined") && (typeof o1[prop] != "undefined")) {
        // prop exists in o1 but not in o2
        diff.push(["[" + prop + "]", "undefined", o1[prop], undefined]); // prop exists in o1 but not in o2

      }
      else {
        // per element nested diff
        var innerDiff = DiffObjects(o1[prop], o2[prop]);
        if (innerDiff) { // o1[prop] != o2[prop]
          // merge diff array into parent's while including parent object name ([prop])
          push.apply(diff, map(innerDiff, function(o, j) { o[0] = "[" + prop + "]" + o[0]; return o; }));
        }

      }
    }
    for (var prop in o2) {
      // the double check in o2 is because in V8 objects remember keys set to undefined
      if ((typeof o1[prop] == "undefined") && (typeof o2[prop] != "undefined")) {
        // prop exists in o2 but not in o1
        diff.push(["[" + prop + "]", "undefined", undefined, o2[prop]]); // prop exists in o2 but not in o1

      }
    }
    // if any differences were found, return them
    if (diff.length)
      return diff;
    // return nothing if objects equal
    return undefined;
  }
  // if same type and not null or objects or arrays
  // perform primitive value comparison
  if (o1 != o2)
    return [["", "value", o1, o2]];

  // return nothing if values are equal
  return undefined;
}

interface FilterFieldsOptionsInterface {
  value: string,
  systemFields: Fields[],
  key?: string
}

export function filterSystemFields({ value, systemFields, key = 'label' }: FilterFieldsOptionsInterface): Fields[] {
  if (typeof (value) === 'object' || value === undefined) {
    return systemFields;
  }
  const filterValue = normalizeText(value);
  return systemFields.filter(option =>
    normalizeText(option[key]).match(filterValue)).sort((a, b): number => {
      if (normalizeText(a[key]) < normalizeText(b[key])) {
        return -1;
      }

      if (normalizeText(a[key]) > normalizeText(b[key])) {
        return 1;
      }

      return 0;
    });
}

export function normalizeText(text: string = ''): string {
  return text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function isValidCPF(cpf) {
  if (typeof cpf !== 'string') {
    return false;
  }

  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
    return false;
  }

  cpf = cpf.split('').map(el => +el);
  const rest = (count) => (cpf.slice(0, count - 12).reduce((soma, el, index) => (soma + el * (count - index)), 0) * 10) % 11 % 10;

  const isValid = rest(10) === cpf[9] && rest(11) === cpf[10];
  return isValid;
}

export function isIOSDevice() {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

  return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor);
}

